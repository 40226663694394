import React from 'react';
import '../styles/summary.scss';
import { Row, Col, Typography, Divider, message, Button, Space } from 'antd';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import Map from '../components/map/map';
import PlanComponent from '../components/PlanComponent/PlanComponent';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, navigate } from 'gatsby';
import axios from 'axios';

const { Content, Header } = Layout;
const { Title, Text } = Typography;

const Summary = ({ data: { strapiInsurance } }) => {
  const { route, travelDate, transport, plan, vehicle, locations, paymentMethod } = useSelector(
    state => state
  );

  const { API_URL } = useSelector(state => state.config);

  const buy = () => {
    console.log(route);
    const config = {
      method: 'post',
      url: API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        travelDate,
        transport,
        plan,
        vehicle,
        locations,
        paymentMethod
      },
    };

    axios(config)
      .then(({ data }) => {
        console.log(data);
        navigate('payment-successful');
      })
      .catch(() => {
        message.error('Por favor intente de nuevo');
      });
  };

  // TODO: we need a way to calculate the cost
  const cost = '$7.00';
  const transports = { car: 'Vehículo particular', bus: 'bus', train: 'tren', moto: 'moto' };
  return (
    <Layout className="container-summary">
      <Header className="site-layout-background header" style={{ padding: 0 }}>
        <Space size="middle" direction="horizontal">
          <Button
            style={{ textAlign: 'left' }}
            className="icon-color-primary"
            type="text"
            size="large"
            href="/wizard"
            icon={<ArrowLeftOutlined />}
          />
          <Text className="title-step" strong>
            Resumen
          </Text>
        </Space>
      </Header>
      <Content>
        <Row justify="center">
          <Col span={18}>
            <div className="circle">
              <Title level={2}>{cost}</Title>
            </div>
            <Row justify="center">
              <Col span={24} align="center">
                <Text strong type="success">
                  {travelDate && new Date(travelDate).toLocaleDateString()}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Text className="summary-color">RUTA: </Text>
          </Col>
          <Col span={20} offset={2}>
            <div className="summary">
              <Text strong className="summary-color">
                {locations && locations.from.name} / {locations && locations.to.name}{' '}
                {route && (route && route.distance / 1000).toFixed(2)} Kms{' '}
              </Text>
              <Text className="summary-color"> viajando en </Text>{' '}
              <Text strong> {transports[transport]}</Text>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={20} offset={2}>
            <div className="map-container">
              <Map
                className={'map-summary'}
                padding={{
                  bottom: 50,
                  top: 50,
                  left: 50,
                  right: 50,
                }}
                dragpan={false}></Map>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2} className="block-mileage">
            {route && (
              <Row className="route-data">
                <Col span={6}>
                  <div>{route && Math.ceil(route.distance / 1000)} km</div>
                </Col>
                <Col span={6}>
                  <div>{route && Math.ceil(route.duration / 3600)} h</div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}></Col>
        </Row>
        <Divider className="summary-color" orientation="left">
          Plan de seguro
        </Divider>
        <Row>
          <Col span={20} offset={2}>
            <PlanComponent strapiInsurance={strapiInsurance} />
          </Col>
        </Row>
        {vehicle && (
          <>
            <Divider orientation="left"> Seguro del vehículo </Divider>
            <Row>
              <Col span={20} offset={2}>
                <p>{plan}</p>
              </Col>
            </Row>
          </>
        )}
        <Divider className="summary-color" orientation="left">
          Método de pago
        </Divider>
        <Row>
          {paymentMethod && <Col offset={19} span={5}>
            <Link to="/payment" className="btn">
              Cambiar
            </Link>
          </Col>}
        </Row>
        <Row>
          {!paymentMethod && (
            <Col span={20} offset={2} className="btn-link">
              <Link to="/payment" className="btn">
                Seleccionar una forma de pago
              </Link>
            </Col>
          )}
        </Row>
        <Row className="box-shadow">
          {paymentMethod && 
            <>
              <Col span={4} offset={2}>$</Col>
              <Col span={10} offset={2}>Saldo Disponible</Col>
              <Col span={4} offset={2}>{JSON.stringify(paymentMethod.value)}</Col>            
            </>
          }
        </Row>
        <br/>
        <Row>
          <Col span={20} offset={2}>
            <Button
              type="primary"
              block
              size="large"
              className="button-finish"
              disabled={!paymentMethod}
              onClick={() => buy()}>
              COMPRAR
            </Button>
            <br/> <br/>
            <Button
              type="secondary"
              block
              size="large"
              className="button-finish"
              href="/"
              onClick={() => message.success('Processing complete!')}>
              CANCELAR
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($plan: String!) {
    strapiInsurance(id: { eq: $plan }) {
      id
      name
      feature {
        description
        price
      }
    }
  }
`;

Summary.propTypes = {
  data: PropTypes.object,
};

export default Summary;
