import React from 'react';
import { List, Typography } from 'antd';
const { Title, Text } = Typography;
import PropTypes from 'prop-types';

const PlanComponent = ({ strapiInsurance }) => (
  <>
    <Title level={3}>{strapiInsurance.name}</Title>
    <List>
      {strapiInsurance.feature.map((item, ix) => (
        <List.Item key={`feat_${ix}`}>
          <List.Item.Meta title={item.description} />
          <Text className="txt-plan" level={4}>
            {item.price}
          </Text>
        </List.Item>
      ))}
    </List>
  </>
);

PlanComponent.propTypes = {
  strapiInsurance: PropTypes.object,
};

export default PlanComponent;
